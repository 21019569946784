$(document).ready(function () {
  if ($(".freightSelectInput").length > 0) {
    $(".freightSelectInput").click((e) => {
      $.post(
        "/carts/add_freight?freight_id=" + e.target.value,
        {},
        function () {},
        "script"
      );
    });
  }
});
