import dayjs from "dayjs";
import jquery from "jquery";

window.$ = jquery;

export const domReady = (fn) =>
  document.readyState === "interactive" || document.readyState === "complete"
    ? fn()
    : document.addEventListener("DOMContentLoaded", fn);

// TODO: DAQUI PRA BAIXO NÃO ERA PARA ESTAR AQUI E SIM EM UM ARQUIVO SEPARADO
// TEM QUE ESTAR TUDO DENTRO DO TURBO:LOAD
document.addEventListener("turbo:load", () => {
  $(".closeImage").on("click", (e) => {
    let className = $(e.target).attr("data-preview");

    let output = $(`#${className}`)[0];
    let outputContainer = `.${className}Container`;
    let outputUpload = `.${className}Upload`;
    let outputInput = `.${className}Input`;

    output.src = "";

    $(outputContainer).addClass("d-none");
    $(outputUpload).removeClass("d-none");
    $(outputInput).val(null);
  });

  $(".imageUpload").on("change", (e) => {
    let className = $(e.target).attr("data-preview");

    let output = $(`#${className}`)[0];
    let outputContainer = `.${className}Container`;
    let outputUpload = `.${className}Upload`;

    let reader = new FileReader();
    reader.onload = function () {
      output.src = reader.result;
    };
    reader.readAsDataURL(event.target.files[0]);

    // $(outputContainer).removeClass("d-none");
    // $(outputUpload).addClass("d-none");
  });

  $(".copyText").on("click", (e) => {
    let className = $(e.target).attr("data-copy");

    let output = `#${className}`;
    let outputContent = `.${className}_input`;

    $(output).val($(outputContent).attr("data-copy"));
  });
}); // END TEM QUE ESTAR TUDO DENTRO DO TURBO:LOAD
