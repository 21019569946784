import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["codeField", "countdown", "sendCode", "nextButton"];

  connect() {
    this.checkForm();
    this.countdown();
  }

  checkForm() {
    // console.log("passo 2");
    // if (this.codeFieldTarget.value.length < 5) {
    //   this.nextButtonTarget.classList.add("disabled");
    // } else {
    //   this.nextButtonTarget.classList.remove("disabled");
    // }
  }

  countdown() {
    this.startTimer(
      60 * 0.5,
      this.countdownTarget,
      this.sendCodeTarget,
      this.countdownTarget
    );
  }

  startTimer(duration, display, sendCode, countdown) {
    let timer = duration,
      minutes,
      seconds;
    setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      display.textContent = "Reenviar em: " + minutes + ":" + seconds;

      if (--timer < 0) {
        timer = duration;

        sendCode.classList.remove("d-none");
        countdown.classList.add("d-none");
      }
    }, 1000);
  }
}
