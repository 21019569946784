import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "cnpjField",
    "fantasyNameField",
    "companyNameField",
    "lineOfBusinessField",
    "nextButton",
  ];

  connect() {
    // this.checkForm();
  }

  checkForm() {
    // if (
    //   this.cnpjFieldTarget.value.length < 17 ||
    //   this.fantasyNameFieldTarget.value.length < 10 ||
    //   this.companyNameFieldTarget.value.length < 10
    // ) {
    //   this.nextButtonTarget.classList.add("disabled");
    // } else {
    //   this.nextButtonTarget.classList.remove("disabled");
    // }
  }
}
