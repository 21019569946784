import jquery from "jquery";
window.$ = jquery;

$(document).ready(function () {
  $(".category_search").change(function () {
    let id_state = $(".category_search").val();
    $.get("/list/subcategories/" + id_state, function (data) {
      let x;
      $("#subcategory").html("");
      $("#subcategory").append(
        "<option value> Selecione a Sub categoria </option >"
      );
      for (x in data) {
        let option =
          '<option value ="' + data[x].id + '">' + data[x].name + "</option >";
        $("#subcategory").append(option);
      }
    });
  });
  $("#search_properties").change(function () {
    let grid_id = $(this).val();
    if (grid_id) {
      $.get("/search/property/" + grid_id, function (data) {
        let x;
        $("#set_properties").html("");
        $("#set_properties").append("<option value>Selecione</option >");
        for (x in data) {
          let option =
            '<option value ="' +
            data[x].id +
            '">' +
            data[x].name +
            "</option >";
          $("#set_properties").append(option);
        }
      });
    }
  });
});
