import Rails from "@rails/ujs";
import * as bootstrap from "bootstrap";
import jquery from "jquery";

window.jQuery = jquery;
window.$ = jquery;
window.bootstrap = bootstrap;

import initFeather from "./feather";
import initVmasker from "./vmasker";
import "./pnotify";

import "./cocoon";
import "./map";
import "./select_searchs";
import "./bs_tooltip";
import "./cartQuantityItems";
import "./add_cart_items";
import "./calculate_freight";
import "./calc_installtment";
import "./select_address";
// import "./select2";
import "./fancybox";
import "./rater";
import "./inputmask";

Rails.start();
