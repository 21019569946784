import { application } from "../application";

import Onboarding__PreController from "./onboarding/pre_controller";
application.register("onboarding--pre", Onboarding__PreController);

import Onboarding__Passo1Controller from "./onboarding/passo1_controller";
application.register("onboarding--passo1", Onboarding__Passo1Controller);

import Onboarding__Passo2Controller from "./onboarding/passo2_controller";
application.register("onboarding--passo2", Onboarding__Passo2Controller);

import Onboarding__Passo3Controller from "./onboarding/passo3_controller";
application.register("onboarding--passo3", Onboarding__Passo3Controller);

import Onboarding__Passo4Controller from "./onboarding/passo4_controller";
application.register("onboarding--passo4", Onboarding__Passo4Controller);

import Onboarding__Passo5Controller from "./onboarding/passo5_controller";
application.register("onboarding--passo5", Onboarding__Passo5Controller);
