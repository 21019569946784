document.addEventListener("turbo:load", () => {
  if ($("#entry_bank_slip").length > 0) {
    $("#entry_bank_slip").on("keyup", (e) => {
      console.log(e.target.value);
      $.get(
        "/checkout/entry_bank_slip_pix?entry=" +
          e.target.value +
          "&type_pay=bankslip",
        {},
        function () {},
        "script"
      );
    });
  }

  if ($("#entry_pix").length > 0) {
    $("#entry_pix").on("keyup", (e) => {
      console.log(e.target.value);
      $.get(
        "/checkout/entry_bank_slip_pix?entry=" +
          e.target.value +
          "&type_pay=pix",
        {},
        function () {},
        "script"
      );
    });
  }

  window.copy_data = (containerid) => {
    let range = document.createRange();
    range.selectNode(containerid);
    window.getSelection().removeAllRanges(); 
    window.getSelection().addRange(range); 
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    $('.coppy').text("Copiado!")
  }
});
