import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["nextButton"];

  connect() {
    // this.checkForm();
  }

  checkForm() {
    // if (document.querySelector("input[type=radio]:checked")) {
    //   this.nextButtonTarget.classList.remove("disabled");
    // } else {
    //   this.nextButtonTarget.classList.add("disabled");
    // }
  }
}
