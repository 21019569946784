import { Fancybox, Carousel } from "@fancyapps/ui";
window.Fancybox = Fancybox;
window.Carousel = Carousel;

document.addEventListener("turbo:load", () => {
  if (document.querySelector("#productCarousel")) {
    // Initialise Carousel
    const productCarousel = new Carousel(
      document.querySelector("#productCarousel"),
      {
        Dots: false,
      }
    );

    // Thumbnails
    const productThumbCarousel = new Carousel(
      document.querySelector("#productThumbCarousel"),
      {
        Sync: {
          target: productCarousel,
          friction: 0,
        },
        Dots: false,
        Navigation: false,
        center: true,
        slidesPerPage: 1,
        infinite: false,
      }
    );

    // Customize Fancybox
    Fancybox.bind('[data-fancybox="gallery"]', {
      Carousel: {
        on: {
          change: (that) => {
            productCarousel.slideTo(
              productCarousel.findPageForSlide(that.page),
              {
                friction: 0,
              }
            );
          },
        },
      },
    });
  }
});
