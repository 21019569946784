$(document).ready(function () {
  // console.log("CHEGUEI");
  $(".selectPropertyGrid").on("click", (e) => {
    console.log($(e.target));
    console.log($(e.target).attr("data-grid"));
    $(`#currentPropertyId`).val($(e.target).val());
    $(`#gridIdChange`).val($(e.target).attr("data-grid"));
    $(`#formGridSubmit`).click();
  });

  $(".quantityFieldCartItem").change((e) => {
    // $.put(
    //   `/carts/add_item?amount=${e.target.value}&variation=${$(e.target).attr(
    //     "data-variation"
    //   )}`,
    //   {},
    //   function () {},
    //   "script"
    // );

    $.ajax({
      url: `/carts/add_item?amount=${e.target.value}&variation=${$(
        e.target
      ).attr("data-variation")}`,
      type: "PUT",
      success: () => {},
      data: {},
    });
  });

  if ($("#add_cart_button").length > 0) {
    const HREF = $("#add_cart_button").attr("data-href");
    const HREF_BUY_NOW = $("#buy_now_button").attr("data-href");

    $("#button_remove_item").click(() => {
      disabledButtons();
      setTimeout(() => {
        updateCartData();
      }, 500);
    });
    $("#button_add_item").click(() => {
      disabledButtons();
      setTimeout(() => {
        updateCartData();
      }, 500);
    });

    const updateCartData = () => {
      let variation = $("#variationId").val();
      let amount = $("input[name=product_amount]").val();
      $("#add_cart_button").removeClass("disabled");
      $("#buy_now_button").removeClass("disabled");
      $("#add_cart_button").attr(
        "href",
        `${HREF}?variation=${variation}&amount=${amount}`
      );
      $("#buy_now_button").attr(
        "href",
        `${HREF_BUY_NOW}?variation=${variation}&amount=${amount}`
      );

      $("#button_remove_item").removeClass("disabled");
      $("#button_add_item").removeClass("disabled");
      $("#add_cart_button").removeClass("disabled");
      $("#buy_now_button").removeClass("disabled");
    };

    const disabledButtons = () => {
      $("#button_remove_item").addClass("disabled");
      $("#button_add_item").addClass("disabled");
      $("#add_cart_button").addClass("disabled");
      $("#buy_now_button").addClass("disabled");
    };
  }
});
