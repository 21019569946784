import jquery from "jquery";
window.$ = jquery;

document.addEventListener("turbo:load", () => {
  $(document).ready(function () {
    function incrementValue(e) {
      let fieldName = $(e.target).data("field");
      let parent = $(e.target).closest("div");
      // let id = $(e.target).attr('data-id')
      let currentVal = parseInt(
        parent.find("input[name=" + fieldName + "]").val(),
        10
      );

      if (!isNaN(currentVal)) {
        parent.find("input[name=" + fieldName + "]").val(currentVal + 1);
      } else {
        parent.find("input[name=" + fieldName + "]").val(1);
      }
      // $('#form_item_'+id).submit()
    }

    function decrementValue(e) {
      let fieldName = $(e.target).data("field");
      let parent = $(e.target).closest("div");
      let currentVal = parseInt(
        parent.find("input[name=" + fieldName + "]").val(),
        10
      );

      if (!isNaN(currentVal) && currentVal > 1) {
        parent.find("input[name=" + fieldName + "]").val(currentVal - 1);
      } else {
        parent.find("input[name=" + fieldName + "]").val(1);
      }
    }

    $(".input-group").on("click", ".button-plus", function (e) {
      incrementValue(e);
    });

    $(".input-group").on("click", ".button-minus", function (e) {
      decrementValue(e);
    });
  });
});
