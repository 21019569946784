document.addEventListener("turbo:load", () => {
  if ($(".addressSelectInput").length > 0) {
    $(".addressSelectInput").on("click", (e) => {
      console.log("CLICK");
      console.log(e.target.value);
      $.post(
        "/checkout/add_address?address_id=" + e.target.value,
        {},
        function () {},
        "script"
      );
    });
  }

  if ($(".addressSelectInputCondominium").length > 0) {
    $(".addressSelectInputCondominium").on("change", (e) => {
      console.log("CLICK");
      console.log(e.target.value);
      $.post(
        "/checkout/add_address?address_id=" + e.target.value,
        {},
        function () {},
        "script"
      );
    });
  }

  if ($(".addressSelectInputMain").length > 0) {
    $(".addressSelectInputMain").on("click", (e) => {
      console.log("CLICK");
      console.log(e.target.value);
      $.post(
        "/account/add_address?address_id=" + e.target.value,
        {},
        function () {
          window.location.reload();
        },
        "script"
      );
    });
  }
});
