import jquery from "jquery";
import "jquery-mask-plugin";
import "jquery-maskmoney/src/jquery.maskMoney";

window.$ = jquery;

document.addEventListener("turbo:load", () => {
  $(".inputmask")
    .maskMoney({
      decimal: ",",
      thousands: ".",
      precision: 2,
      affixesStay: true,
      allowZero: true,
    })
    .trigger("mask.maskMoney");
});
