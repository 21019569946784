import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["nameField", "emailField", "phoneField", "nextButton"];

  connect() {
    // this.checkForm();
  }

  checkForm() {
    // if (
    //   this.nameFieldTarget.value.length < 5 ||
    //   this.emailFieldTarget.value.length < 5 ||
    //   this.phoneFieldTarget.value.length < 14
    // ) {
    //   this.nextButtonTarget.classList.add("disabled");
    // } else {
    //   this.nextButtonTarget.classList.remove("disabled");
    // }
  }
}
