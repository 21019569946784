import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "nameField",
    "cpfField",
    "emailField",
    "phoneField",
    "roleField",
    "nextButton",
  ];

  connect() {
    // this.checkForm();
  }

  checkForm() {
    // if (
    //   this.nameFieldTarget.value.length < 5 ||
    //   this.cpfFieldTarget.value.length < 14 ||
    //   this.emailFieldTarget.value.length < 5 ||
    //   this.phoneFieldTarget.value.length < 14
    //   // || this.roleFieldTarget.value.length < 17
    // ) {
    //   this.nextButtonTarget.classList.add("disabled");
    // } else {
    //   this.nextButtonTarget.classList.remove("disabled");
    // }
  }
}
