// var rater = require("rater-js");
import raterJs from "rater-js";

document.addEventListener("turbo:load", () => {
  const targetRating = document.querySelector("#rate_rate");
  const rating = document.querySelector("[data-raty]");

  if (rating) {
    const starRating = raterJs({
      element: rating,
      rateCallback: function rateCallback(rating, done) {
        starRating.setRating(rating);
        done();
      },
      onHover: function (currentIndex, currentRating) {
        // document.querySelector(".live-rating").textContent = currentIndex;
        targetRating.value = currentIndex;
      },
      onLeave: function (currentIndex, currentRating) {
        // document.querySelector(".live-rating").textContent = currentRating;
        targetRating.value = currentIndex;
      },
      starSize: 24,
    });
  }
});
